export default {
    namespaced: true,
    state: {
      info: JSON.parse(localStorage.getItem('userData')) || { name: 'hi' }
    },
    getters: {},
    mutations: {
      UPDATE_PROFILE(state, val) {
        
        state.info = val
      },
    },
    actions: {},
  }
  