import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import po_master from "./routes/po_master";
import { nextTick } from "vue";
import vendor_list_po from "./routes/vendor_list_po";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/pages/Dashboard.vue"),
        meta: { title: "Dashboard" },
      },
      {
        path: "/dashboard/on-going-order/detail",
        name: "dashboard-on-going-order",
        component: () =>
          import("@/views/pages/dashboard_detail/Ongoing_order.vue"),
        meta: { title: "Pesanan Berjalan" },
      },
      {
        path: "/dashboard/need-approval/update",
        name: "dashboard-need-approval",
        component: () =>
          import("@/views/pages/dashboard_detail/Need_approval.vue"),
        meta: { title: "Butuh Persetujuan" },
      },
      ...po_master,
      ...vendor_list_po,
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/pages/Profile.vue"),
        meta: { title: "Profile" },
      },
      {
        path: "/print",
        name: "print",
        component: () => import("@/views/pages/Print.vue"),
        meta: { title: "Print" },
      }
    ],
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/pages/auth/Login.vue"),
    meta: { title: "Sign In" },
  },
  {
    path: "/sign-in-dev",
    name: "sign-in-dev",
    component: () => import("@/views/pages/auth/LoginDev.vue"),
    meta: { title: "Sign In" },
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
    meta: { title: "404" },
  },
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/crafted/authentication/Error401.vue"),
    meta: { title: "401" },
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
    meta: { title: "500" },
  },
  {
    path: "/mt",
    name: "error-mt",
    component: () => import("@/views/crafted/authentication/ErrorMT.vue"),
    meta: { title: "Maintanance" },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
});

router.beforeEach((to) => {
  document.title = process.env.VUE_APP_NAME + " | " + to.meta.title;
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.commit(Mutations.CHECK_MAINTANANCE);
  store.commit(Mutations.MIDDLEWARE_PAGE, to.matched);
  if (JwtService.getToken() == null) {
    store.commit(Mutations.PURGE_AUTH);
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
