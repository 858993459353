export default [
  {
    path: "/vendor-list-po",
    component: () => import("@/views/pages/Index.vue"),
    children: [
      {
        path: "update/:id",
        name: "vendor-list-po-purchase-order-detail",
        component: () => import("@/views/pages/vendor_list_po/Detail.vue"),
        meta: { title: "Detail Purchase Order" },
      },
    ],
  },
];
