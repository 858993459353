// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'http://localhost:8000',
  headers: {
    'Access-Control-Allow-Origin' : 'http://localhost:3005/',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json'
  }
  // timeout: 1000,
})
export default axiosIns
