import { formatCurrency as currencyFormat } from '@/utils/formatCurrency';
import moment from "moment";

function formatCurrency(e,currency, ...options) {
    return currencyFormat(e, currency, options);
}

function format_date(value){
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY')
     }else{
         return '-'
     }
}

function format_date_time(value){
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
     }else{
         return '-'
     }
}

function format_date_hour_minute(value){
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY HH:mm')
     }else{
         return '-'
     }
}

function format_date_time_dot(value){
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY HH.mm.ss')
     }else{
         return '-'
     }
}

function generateAvatar(name){
    let colors = [
        'ab60b8', 'ef6c00', '23b6c9', '172568',
        '303e45', '2196f3', '37475d', '1354b0',
        'b2bf2d', '394aa5', '00b098', 'eb673e',
        '1e87da', '714dad', '515ea8', 'de5b87',
        '1b8ccf', '5b5bc3',
    ];
    return 'https://ui-avatars.com/api/?name=' + encodeURI(name) + '&length=3&color=FFFFFF&bold=true&background='+colors[Math.floor((Math.random()*colors.length))];
}

function payloadCheck(model,fields){
    let newPayload = {}
    let newFields = []
    let fieldsAll = fields.list
    for(var i in fieldsAll){
        let row = fieldsAll[i]
        if(row.type == 'row'){
            let subfields = row.subfields 
            for(var j in subfields){
                let rows2 = subfields[j]
                newFields.push({...rows2})
            }
        }else{
            newFields.push({...row})
        }
    }
    for(var i in Object.keys(model)){
        let v_key = Object.keys(model)[i]
        let fieldA = newFields
        let fFields = fieldA.filter(data => data.key === v_key)
        if(fFields.length > 0){
            if(fFields[0].notRequest != true){
                newPayload[v_key] = model[v_key]
            }
        }else{
            newPayload[v_key] = model[v_key]
        }
    }
    return newPayload;
}

export {
    formatCurrency,
    format_date,
    generateAvatar,
    format_date_time,
    format_date_time_dot,
    format_date_hour_minute,
    payloadCheck
}