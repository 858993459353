import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "./core/services/ApiService";
import ApiMah from "./libs/axios";
import ApiServiceHRIS from "./libs/axiosHRIS";
import ApiServiceOPEX from "./libs/axiosOPEX";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
// import "bootstrap-vue";
import * as mix from '@/utils/mix';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();
app.config.globalProperties.$apihttp = ApiMah;
app.config.globalProperties.$apiservice = ApiService;
// app.config.globalProperties.$apiopex = ApiServiceOPEX;
// app.config.globalProperties.$apihris = ApiServiceHRIS;
app.config.globalProperties.$mix = mix;

app.use(i18n);

app.mount("#app");
