export default [
    {
        path: "/purchase-order",
        component: () => import("@/views/pages/Index.vue"),
        children: [
            {
                path: "",
                name: "procurement-master-purchase-order",
                component: () => import("@/views/pages/master_po/List.vue"),
                meta:{title: "Master Purchase Order"}
            },
                {
                path: "create",
                name: "procurement-master-purchase-order-create",
                component: () => import("@/views/pages/master_po/Create.vue"),
                meta:{title: "Tambah Master Purchase Order"}
            },
                {
                path: "update/:id",
                name: "procurement-master-purchase-order-edit",
                component: () => import("@/views/pages/master_po/Edit.vue"),
                meta:{title: "Edit Master Purchase Order"}
            },
                {
                path: "detail/:id",
                name: "procurement-master-purchase-order-detail",
                component: () => import("@/views/pages/master_po/Detail.vue"),
                meta:{title: "Detail Master Purchase Order"}
            },
        ],
      },
]