import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_API_URL_FPS;
    ApiService.vueInstance.axios.defaults.headers.common["x-api-key"] =
      process.env.VUE_APP_X_API_KEY_VENDOR;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(req = null): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    if (req != null) {
      ApiService.vueInstance.axios.defaults.headers.common[
        "Content-Disposition"
      ] = "attachment; filename=template.xlsx";
      ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      ApiService.vueInstance.axios.defaults["responseType"] = "arraybuffer";
    } else {
      delete ApiService.vueInstance.axios.defaults.headers.common[
        "Content-Disposition"
      ];
      delete ApiService.vueInstance.axios.defaults.headers.common[
        "Content-Type"
      ];
      delete ApiService.vueInstance.axios.defaults["responseType"];
      ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
        "application/json";
      ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
        "application/json";

      // untuk x-signature-key
      ApiService.vueInstance.axios.defaults.headers.common["x-signature-key"] =
        localStorage.getItem("signature_key");
    }
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    // return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
