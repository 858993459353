import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export interface User {
  code:number;
  data:{
    allow_approval: string;
    branch_name: string;
    department_name: string;
    email: string;
    fullname: string;
    is_active: boolean;
    nip: string;
    user_roles: string;
    username: string;
  }
  error:boolean;
  message:string;
}

export interface StoreInfo {
  classes: {
    header?: Array<string>;
    headerContainer?: Array<string>;
    headerMobile?: Array<string>;
    headerMenu?: Array<string>;
    aside?: Array<string>;
    asideMenu?: Array<string>;
    asideToggle?: Array<string>;
    toolbar?: Array<string>;
    toolbarContainer?: Array<string>;
    content?: Array<string>;
    contentContainer?: Array<string>;
    footerContainer?: Array<string>;
    sidebar?: Array<string>;
    pageTitle?: Array<string>;
  };
  isPageActive: boolean;
  isPageProfile: boolean;
  isPageChild: Array<any>;
  listMenu: any[];
  dataUser:User;
}

@Module
export default class BodyModule extends VuexModule implements StoreInfo {
  classes = {};
  isPageActive = false;
  isPageProfile = false;
  isPageChild = [];
  listMenu:Array<{}> = [];
  dataUser = {} as User;
  /**
   * Get current page title
   * @returns string
   */
  get getClasses() {
    return (position) => {
      if (typeof position !== "undefined") {
        return this.classes[position];
      }
      return this.classes;
    };
  }

  get getIsPageActive(): boolean {
    return this.isPageActive;
  }
  
  get getIsPageProfile(): boolean {
    return this.isPageProfile;
  }

  @Mutation
  [Mutations.SET_CLASSNAME_BY_POSITION](payload) {
    const { position, className } = payload;
    if (!this.classes[position]) {
      this.classes[position] = [];
    }
    this.classes[position].push(className);
  }

  @Mutation
  [Mutations.PAGE_MENU]() {
      let menu = []
      let new_menu:Array<{}> = []
      let new_menu2:Array<{}> = []
      ApiService.setHeader()
      ApiService.get('/user/v1/menus').then(res=>{
        menu = res.data.data
        for(var i in menu){
          new_menu.push(menu[i])
          for(var b in menu[i]['childs']){
            new_menu.push(menu[i]['childs'][b])
          }
        }
        for(var i in new_menu){
          new_menu2.push({...new_menu[i]})
          if(new_menu[i]['childs']['length'] > 0){
            let parent = new_menu[i]
            for(var a in new_menu[i]['childs']){
              let row = new_menu[i]['childs'][a]
              row['parent_action'] = row.action
              new_menu2.push({...row})
              // this.listMenu.push({...row})

              if(row['childs']){
                if(row['childs']['length'] > 0){
                  for(var b in row['childs']){
                    let rowb = row['childs'][b]
                    rowb['parent_action'] = row.action
                    new_menu2.push({...rowb})
                  }
                }
              }
            }
          }
        }
        this.listMenu = new_menu2
        window.localStorage.setItem('listPageMenu', JSON.stringify(new_menu2))
      })
  }
  @Mutation
  [Mutations.MIDDLEWARE_PAGE](payload) {
    this.isPageActive = true
    return false
    if(payload.length > 1) {
      let profile:Array<{}> []
      if(window.localStorage.getItem('user') != null){
        profile = JSON.parse(window.localStorage.getItem('user') || '')
        if(profile['data']['path_signature'] == null){
          this.isPageProfile = true
        }else{
          this.isPageProfile = false
        }
      }else{
        profile = JSON.parse('[]' || '')
        this.isPageProfile = false
      }
      if(payload[1].path == '/profile'){
        return this.isPageActive = true
      }else if(payload[1].path == '/dashboard'){
        return this.isPageActive = true
      }else if(payload[2] != undefined){
        if(payload[2].name == 'procurement-purchase-request-detail-v2'){
          return this.isPageActive = true
        }else if(payload[2].name == 'procurement-purchase-order-detail-v2'){
          return this.isPageActive = true
        }else{
          let menu:Array<{}> []
          if(window.localStorage.getItem('listPageMenu') != null){
            menu = JSON.parse(window.localStorage.getItem('listPageMenu') || '')
          }else{
            menu = JSON.parse('[]' || '')
          }
          let url:any = null
          let path:any = ''
          let link:any = null
          let sub:any = []
          if(payload.length > 2){
            path = payload[2].path
            url = path.search("/:id")
          }else{
            path = payload[1].path
            url = path.search("/:id")
          }
  
          if(url != '-1'){
            link = path.substring(0,url)
          }else{
            link = path
          }
  
          let find = menu.filter(data => {
            return data['action'] === link
          })
          if(find.length > 1) {
            if(find.length == 2){
              let menu3 = menu.filter(datas => datas['action'] === find[0]['parent_action'])
              if(menu3.length > 0){
                for(var j in menu3[0]['childs']){
                  let rowc = menu3[0]['childs'][j]
                  sub.push(rowc['name'])
                }
              }
            }else{
              for(var j in find[0]['childs']){
                let rowd = find[0]['childs'][j]
                sub.push(rowd['name'])
              }
            }
            this.isPageChild = sub
            return this.isPageActive = true
          }else{
            return this.isPageActive = false
          }
        }
      }else if(payload[1].path == '/change-password'){
        return this.isPageActive = true
      }else if(payload[1].path == '/sign-in'){
        return this.isPageActive = true
      }else if(payload[1].path == '/sign-in-dev'){
        return this.isPageActive = true
      }else if(payload[1].path == '/print'){
        return this.isPageActive = true
      }else{
          let menu:Array<{}> []
          if(window.localStorage.getItem('listPageMenu') != null){
            menu = JSON.parse(window.localStorage.getItem('listPageMenu') || '')
          }else{
            menu = JSON.parse('[]' || '')
          }
          let url:any = null
          let path:any = ''
          let link:any = null
          let sub:any = []
          if(payload.length > 2){
            path = payload[2].path
            url = path.search("/:id")
          }else{
            path = payload[1].path
            url = path.search("/:id")
          }
  
          if(url != '-1'){
            link = path.substring(0,url)
          }else{
            link = path
          }
  
          let find = menu.filter(data => {
            return data['action'] === link
          })
          if(find.length > 1) {
            if(find.length == 2){
              let menu3 = menu.filter(datas => datas['action'] === find[0]['parent_action'])
              if(menu3.length > 0){
                for(var j in menu3[0]['childs']){
                  let rowc = menu3[0]['childs'][j]
                  sub.push(rowc['name'])
                }
              }
            }else{
              for(var j in find[0]['childs']){
                let rowd = find[0]['childs'][j]
                sub.push(rowd['name'])
              }
            }
            this.isPageChild = sub
            return this.isPageActive = true
          }else{
            return this.isPageActive = false
          }
        // })
      }
    }else{
      return this.isPageActive = true
    }
  }


  @Action
  [Actions.ADD_BODY_CLASSNAME](className) {
    document.body.classList.add(className);
  }

  @Action
  [Actions.REMOVE_BODY_CLASSNAME](className) {
    document.body.classList.remove(className);
  }

  @Action
  [Actions.ADD_BODY_ATTRIBUTE](payload) {
    const { qulifiedName, value } = payload;
    document.body.setAttribute(qulifiedName, value);
  }

  @Action
  [Actions.REMOVE_BODY_ATTRIBUTE](payload) {
    const { qulifiedName } = payload;
    document.body.removeAttribute(qulifiedName);
  }

  @Action
  [Actions.ADD_CLASSNAME](payload) {
    this.context.commit(Mutations.SET_CLASSNAME_BY_POSITION, payload);
  }
}
