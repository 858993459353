import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import JwtServiceOPEX from "@/core/services/JwtServiceOPEX";
import JwtServiceHRIS from "@/core/services/JwtServiceHRIS";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { useRoute, useRouter } from "vue-router";

export interface User {
  allow_approval: string;
  branch_name: string;
  department_name: string;
  email: string;
  fullname: string;
  is_active: boolean;
  nip: string;
  user_roles: string;
  username: string;
}

export interface Params {
  username: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
  firstLogin: boolean;
  isMaintanance: boolean;
}


@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  testing = null;
  params = {} as Params;
  isAuthenticated = !!JwtService.getToken();
  isMaintanance = false;
  firstLogin = false;
  isError = false;
  /**
   * Get current user object
   * @returns User
   */
  // get currentUser():User{
  //   return this.user;
  // }
  get currentUser(){
    return window.localStorage.getItem('user');
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  
  get isMaintananceWeb(): boolean {
    return this.isMaintanance;
  }

  get getError(): boolean {
    return this.isError;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.CHECK_MAINTANANCE]() {
    // this.config = Object.assign({}, this.initial);
    ApiService.get('/authentication/v1/maintenance').then(res =>{
      let data = res.data.data
      if(data.enabled){
        this.isMaintanance = true
      }else{
        this.isMaintanance = false
      }
    }).catch(response => {
          if(response.isAxiosError){
            this.isError = true
          }
    });
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    
    this.errors = {};
    ApiService.setHeader();
    ApiService.get('/user/v1/me').then(res => {
      window.localStorage.setItem('username', user.username)
      window.localStorage.setItem('user', JSON.stringify(res.data))
      this.firstLogin = true
    })
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_FIRST_LOGIN]() {
    this.firstLogin = false;
    window.location.reload()
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('config');
    ApiService.query('/authentication/v1/logout',{params:{"userName":window.localStorage.getItem('username')}})
    window.localStorage.removeItem('username');
    window.localStorage.removeItem('listPageMenu');
    JwtService.destroyToken();
    JwtServiceOPEX.destroyToken();
    JwtServiceHRIS.destroyToken();
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  
}
